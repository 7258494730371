/* @font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik'), local('Rubik-Regular'),
    url('./fonts/rubik-regular-webfont.woff2') format('woff2'),
    url('./fonts/rubik-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Rubik Medium'), local('Rubik-Medium'),
    url('./fonts/rubik-medium-webfont.woff2') format('woff2'),
    url('./fonts/rubik-medium-webfont.woff') format('woff');
} */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f4f6fa;
}

body.noScroll {
  overflow: hidden;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

.App {
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: 0;
  min-width: 0;
  font-family: Rubik, sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.VimeoContainer {
  width: 100%;
  position: relative;
  padding-top: 66.66%;
  overflow: hidden;
}

.VimeoIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.RichText h1 {
  margin: 0 0 10px 0;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.RichText h2 {
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 10px 0;
  font-weight: 500;
}

.RichText p {
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 23px;
}

.RichText li {
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 10px;
}

[data-component="ActionButtonPRIMARY"]:hover:not([disabled]) > div,
[data-component="ActionButtonPRIMARY"]:focus:not([disabled]) > div,
[data-component="ActionButtonPRIMARY"]:active:not([disabled]) > div {
  background-color: #3170b8cc !important;
}

[data-component="ActionButtonSECONDARY"]:hover:not([disabled]) > div,
[data-component="ActionButtonSECONDARY"]:focus:not([disabled]) > div,
[data-component="ActionButtonSECONDARY"]:active:not([disabled]) > div {
  background-color: #3170b8 !important;
}

[data-component="ActionButtonSECONDARY"]:active:not([disabled]) div,
[data-component="ActionButtonSECONDARY"]:hover:not([disabled]) div,
[data-component="ActionButtonSECONDARY"]:focus:not([disabled]) div,
[data-component="ActionButtonSECONDARY"]:active:not([disabled]) i,
[data-component="ActionButtonSECONDARY"]:focus:not([disabled]) i,
[data-component="ActionButtonSECONDARY"]:hover:not([disabled]) i,
[data-component="ActionButtonDANGER"]:active:not([disabled]) div,
[data-component="ActionButtonDANGER"]:hover:not([disabled]) div,
[data-component="ActionButtonDANGER"]:focus:not([disabled]) div,
[data-component="ActionButtonDANGER"]:active:not([disabled]) i,
[data-component="ActionButtonDANGER"]:focus:not([disabled]) i,
[data-component="ActionButtonDANGER"]:hover:not([disabled]) i {
  color: #ffffff !important;
}

input:focus,
textarea:focus {
  border: #3170b844 solid 2px;
  outline: none;
}

a:focus,
div:focus {
  outline-color: #3170b844;
  outline-width: 2px;
}

/*
WTC Sign In Button preferred styles.
See https://grnspace.github.io/wtc-oidc-client/oidc/
Styles are from CDN: https://cdn-ca.grnspace.co/wtc_signin.css

 */
.wtc-signin-anchor {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  border-radius: 3px !important;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 1px 1px
    rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.12) !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -webkit-transition: background-color 0.218s, -webkit-box-shadow 0.218s !important;
  transition: background-color 0.218s, -webkit-box-shadow 0.218s !important;
  -o-transition: background-color 0.218s, box-shadow 0.218s !important;
  transition: background-color 0.218s, box-shadow 0.218s !important;
  transition: background-color 0.218s, box-shadow 0.218s, -webkit-box-shadow
    0.218s !important;
  -webkit-user-select: none !important;
  -webkit-appearance: none !important;
  user-select: none !important;
  appearance: none !important;
  background-color: #fff !important;
  background-image: none !important;
  color: #333333 !important;
  cursor: pointer !important;
  outline: none !important;
  position: relative !important;
  text-align: center !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 100% !important;
  justify-content: center;
  text-decoration: none !important;
  padding: 12px 16px !important;
  font-weight: 500 !important;
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  border: 0 !important;
}

.wtc-signin-anchor:hover {
  background-color: #e7001a !important;
  color: #fff !important;
}

.wtc-signin-leaf {
  margin-right: 12px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 32px !important;
  height: 32px !important;
}

.wtc-signin-leaf-path {
  fill: #e7001a !important;
}

.wtc-signin-anchor:hover .wtc-signin-leaf-path {
  fill: #fff !important;
}
